import { Button } from "primereact/button";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import authFetch from "../axios";

const SingleRegistrars = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

  const [rez, setRez] = useState({});
  const [reg, setReg] = useState(0);

  useEffect(() => {
    const getStatz = async () => {
      try {
        const statistics = await authFetch.get(`/dcr/registrar/single/${id}`);
        console.log(statistics.data);
        setRez(statistics.data);
      } catch (error) {
        console.log(error);
      }
    };
    const getNz = async () => {
      setIsLoading(true);
      try {
        const statistics = await authFetch.get(`/dcr/registrar/count/${id}`);

        setReg(statistics.data.total);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    getStatz();
    getNz();
  }, [id]);

  return (
    <div>
      <div className="surface-card p-4 shadow-2 border-round my-2">
        <Button
          label="Go back"
          icon="pi pi-arrow-left"
          className="p-button-sm w-2 my-3"
          onClick={goBack}
        />
        <div className="flex flex-column md:flex-row md:flex-wrap pt-2 border-top-1 border-bottom-1 border-green-300">
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Name</div>
            <div className="text-900">{rez?.UserName}</div>
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Phone Number</div>
            <div className="text-900">{rez?.Phone_No}</div>
          </div>
          <div className="col-12 md:col-4 p-3">
            <div className="text-500 font-medium mb-2">Email Address</div>
            <div className="text-900">{rez?.Email}</div>
          </div>
          <div className="col-12 md:col-4 p-3">
            <div className="text-500 font-medium mb-2"> STATE</div>
            <div className="text-900">{rez && rez.State?.State_Name}</div>
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-600 font-bold text-sm mb-2">
              TOTAL REGISTRATIONS
            </div>
            <div className="text-900">{reg && reg}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleRegistrars;
