/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";

import { Image } from "primereact/image";
import { Button } from "primereact/button";
import {
  ApproveBirthRegistrationsApi,
  QueryBirthRegistrationsApi,
  getSingleBirthRegs,
} from "../redux/apiCalls";
import {
  approvalStatus,
  dateFormatter,
  getApprovalStatusKey,
  getBgcolor,
  reverse_option_value,
  RoleName,
} from "../utils";
import ApprovalActions from "../components/approvelActions";
import RecordDetails from "../components/recordDetails";
import { sexType } from "../helpers/constants";
import StatusStrip from "../components/statusStrip";

const SingleBirth = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [approveSuccess, setApproveSuccess] = useState(false);
  const [records, setRecords] = useState([]);
  const [queriedReason, setQueriedReason] = useState([]);
  const [color, setColor] = useState("");

  const getSingleRecord = async () => {
    setLoading(true);
    try {
      const response = await getSingleBirthRegs(id);
      setLoading(false);
      setRecords(response.data);
      setQueriedReason(response.queried);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleRecord();
  }, [id, approveSuccess]);

  useEffect(() => {
    const color = getBgcolor(+records?.Approval_Status);
    setColor(color);
  }, [records, approveSuccess]);

  if (loading) {
    return (
      <div className="flex justify-content-center align-items-center">
        <ProgressSpinner style={{ width: "3rem", height: "3rem" }} />
      </div>
    );
  }
  return (
    <div>
      <div>
        <Button
          label="Go back"
          icon="pi pi-arrow-left"
          className="p-button-sm my-2"
          onClick={goBack}
        />
      </div>
      <div className="flex flex-column gap-5">
        <StatusStrip
          color={color}
          status={getApprovalStatusKey(+records.Approval_Status)}
        />

        <div className="bg-white p-4">
          <div className="p-d-flex p-flex-column p-ai-center">
            <div>
              <div className="text-xs">
                <span className="font-semibold">Registered By:</span> {(records?.Registered_ByData?.UserName || '')}
              </div>
              <div className="hidden text-sm text-muted-foreground md:inline">
                <span className="text-xs"
                >
                  <span className="font-semibold">Role:</span> {RoleName(records?.Registered_ByData?.Role_ID || '')}
                </span>
              </div>
            </div>
          </div>
        </div>

        {+records?.Approval_Status === approvalStatus.QUERIED && (
          <RecordDetails
            header="Reason for Querying"
            records={queriedReason}
            fieldsToDisplay={["reason", "time_queried"]}
            fieldProcessors={{
              time_queried: (records) => {
                return dateFormatter(records?.created_at);
              },
              surname: (records) => `${records?.childData?.surname ?? "----"}`,
            }}
          />
        )}


        <RecordDetails
          type="child"
          records={records}
          fieldsToDisplay={[
            "firstname",
            "surname",
            "middle_name",
            "gender",
            "birth_date",
            "face",
          ]}
          fieldProcessors={{
            gender: (records) => {
              return reverse_option_value(records?.childData?.gender, sexType);
            },
            surname: (records) => `${records?.childData?.surname ?? "----"}`,
            firstname: (records) =>
              `${records?.childData?.firstname ?? "----"}`,
            middlename: (records) =>
              `${records?.childData?.middlename ?? "----"}`,
            birth_date: (records) =>
              `${records?.childData?.birth_date ?? "----"}`,
            face: (records) => {
              return (
                <>
                  {records?.ChildFace ? (
                    <Image
                      src={records?.ChildFace}
                      zoomSrc={records?.ChildFace}
                      alt={records.id}
                      preview
                      width="100%"
                      height="150px"
                    />
                  ) : (
                    "----"
                  )}
                </>
              );
            },
          }}
        />
        <RecordDetails
          type="mother"
          records={records}
          fieldsToDisplay={[
            "surname",
            "other_names",
            "country_of_residence",
            "state_of_residence",
            "lga_of_residence",
            "Age_Of_Mother_At_Birth_Of_Child",
            "Marital_Status",
            "Nationality",
            "State_of_Origin",
            "Ethnic_Group",
            "Literacy",
            "Level_Of_Education",
            "Occupation",
            "Phone_Number",
            "NIN",
            "Street_Address",
          ]}
          fieldProcessors={{
            surname: (records) => `${records?.motherData?.surname ?? ""}`,
            other_names: (records) =>
              `${records?.motherData?.firstname ?? ""} ${records?.motherData?.middle_name ?? ""
              }`,
            country_of_residence: (records) =>
              `${records?.motherData?.current_residence_countryData
                ?.Country_Name ?? "----"
              }`,
            state_of_residence: (records) =>
              `${records?.motherData?.current_residence_stateData?.State_Name ??
              "----"
              }`,
            lga_of_residence: (records) =>
              `${records?.motherData?.current_residence_lgaData?.LGA_Name ??
              "----"
              }`,
            Age_Of_Mother_At_Birth_Of_Child: (records) =>
              `${records?.mother_age_at_birth ?? "----"}`,
            Marital_Status: (records) =>
              `${records?.motherData?.current_marital_statusData?.Status_Desc ??
              "----"
              }`,
            Nationality: (records) =>
              `${records?.motherData?.current_nationalityData?.Country_Name ??
              "----"
              }`,
            State_of_Origin: (records) =>
              `${records?.motherData?.origin_stateData?.State_Name ?? "----"}`,
            Ethnic_Group: (records) =>
              `${records?.motherData?.ethnic_groupData?.Ethnic_Grp_Name ?? "----"
              }`,
            Literacy: (records) =>
              `${records?.motherData?.current_literacy_levelData?.Literacy ??
              "----"
              }`,
            Level_Of_Education: (records) =>
              `${records?.motherData?.current_education_levelData?.Description ??
              "----"
              }`,
            Occupation: (records) =>
              `${records?.motherData?.current_occupationData?.Occupation ??
              "----"
              }`,
            Phone_Number: (records) =>
              `${records?.motherData?.current_phone_number ?? "----"}`,
            NIN: (records) => `${records?.motherData?.nin ?? "----"}`,
            Street_Address: (records) =>
              `${records?.motherData?.current_address ?? "----"}`,
          }}
        />
        <RecordDetails
          type="father"
          records={records}
          fieldsToDisplay={[
            "surname",
            "other_names",
            "country_of_residence",
            "state_of_residence",
            "lga_of_residence",
            "Age_Of_Father_At_Birth_Of_Child",
            "Marital_Status",
            "Nationality",
            "State_of_Origin",
            "Ethnic_Group",
            "Literacy",
            "Level_Of_Education",
            "Occupation",
            "Phone_Number",
            "NIN",
            "Street_Address",
          ]}
          fieldProcessors={{
            surname: (records) => `${records?.fatherData?.surname ?? ""}`,
            other_names: (records) =>
              `${records?.fatherData?.firstname ?? ""} ${records?.fatherData?.middle_name ?? ""
              }`,
            country_of_residence: (records) =>
              `${records?.fatherData?.current_residence_countryData
                ?.Country_Name ?? "----"
              }`,
            state_of_residence: (records) =>
              `${records?.fatherData?.current_residence_stateData?.State_Name ??
              "----"
              }`,
            lga_of_residence: (records) =>
              `${records?.fatherData?.current_residence_lgaData?.LGA_Name ??
              "----"
              }`,
            Age_Of_Father_At_Birth_Of_Child: (records) =>
              `${records?.father_age_at_birth ?? "----"}`,
            Marital_Status: (records) =>
              `${records?.fatherData?.current_marital_statusData?.Status_Desc ??
              "----"
              }`,
            Nationality: (records) =>
              `${records?.fatherData?.current_nationalityData?.Country_Name ??
              "----"
              }`,
            State_of_Origin: (records) =>
              `${records?.fatherData?.origin_stateData?.State_Name ?? "----"}`,
            Ethnic_Group: (records) =>
              `${records?.fatherData?.ethnic_groupData?.Ethnic_Grp_Name ?? "----"
              }`,
            Literacy: (records) =>
              `${records?.fatherData?.current_literacy_levelData?.Literacy ??
              "----"
              }`,
            Level_Of_Education: (records) =>
              `${records?.fatherData?.current_education_levelData?.Description ??
              "----"
              }`,
            Occupation: (records) =>
              `${records?.fatherData?.current_occupationData?.Occupation ??
              "----"
              }`,
            Phone_Number: (records) =>
              `${records?.fatherData?.current_phone_number ?? "----"}`,
            NIN: (records) => `${records?.fatherData?.nin ?? "----"}`,
            Street_Address: (records) =>
              `${records?.fatherData?.current_address ?? "----"}`,
          }}
        />

        <RecordDetails
          type="informant"
          records={records}
          fieldsToDisplay={[
            "Relationship_to_child",
            "surname",
            "other_names",
            "country_of_residence",
            "state_of_residence",
            "lga_of_residence",
            "Phone_Number",
            "NIN",
            "Street_Address",
          ]}
          fieldProcessors={{
            Relationship_to_child: (records) =>
              `${records?.informantData?.relationship ?? "----"}`,
            surname: (records) => `${records?.informantData?.surname ?? ""}`,
            other_names: (records) =>
              `${records?.informantData?.firstname ?? ""} ${records?.informantData?.middle_name ?? ""
              }`,
            country_of_residence: (records) =>
              `${records?.informantData?.current_residence_countryData
                ?.Country_Name ?? "----"
              }`,
            state_of_residence: (records) =>
              `${records?.informantData?.current_residence_stateData
                ?.State_Name ?? "----"
              }`,
            lga_of_residence: (records) =>
              `${records?.informantData?.current_residence_lgaData?.LGA_Name ??
              "----"
              }`,
            Phone_Number: (records) =>
              `${records?.informantData?.current_phone_number ?? "----"}`,
            NIN: (records) => `${records?.informantData?.nin ?? "----"}`,
            Street_Address: (records) =>
              `${records?.informantData?.current_address ?? "----"}`,
          }}
        />

        <article className="shadow-lg bg-white  text-sm px-4 py-2 box-shadow-css">
          <h2 className="text-sm text-black-alpha-90 font-bold">
            <span className="border-bottom-2 border-blue-600">Uploaded </span>{" "}
            Documents
          </h2>
          <section
            className="flex flex-wrap gap-3 w-full"
            style={{ wordBreak: "break-word" }}
          >
            {records?.SupportingDocument?.length > 0 ? (
              records.SupportingDocument?.filter(
                (doc) => doc.file !== null
              ).map((filteredDoc) => (
                <div
                  style={{ maxWidth: "30%" }}
                  className="flex flex-wrap gap-3 w-full"
                  key={filteredDoc.type}
                >
                  {filteredDoc.mimeType ? (
                    <>
                      {filteredDoc.mimeType === "application/pdf" ? (
                        <div className="card shadow-lg h-[200px] flex items-center px-2 justify-center rounded-md overflow-hidden">
                          <div className="gap-3 block ">
                            <a
                              className="text-xs"
                              href={filteredDoc.file}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="pi pi-file-pdf h-20 text-center w-full w-2 text-red-700" />
                              {filteredDoc.type?.replace("-", " ")}
                            </a>
                          </div>
                        </div>
                      ) : (
                        <div className="w-full shadow-lg rounded-md overflow-hidden">
                          <p className="w-full capitalize">
                            <span className="font-bold">Document Type: </span>{" "}
                            {filteredDoc.type?.replace("-", " ")}
                          </p>
                          <Image
                            src={filteredDoc.file}
                            zoomSrc={filteredDoc.file}
                            alt={filteredDoc.type}
                            preview
                            width="100%"
                            height="300px"
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        style={{ maxWidth: "30%" }}
                        className="shadow-lg rounded-md overflow-hidden"
                      >
                        <p className="w-full capitalize">
                          <span className="font-bold">Document Type: </span>{" "}
                          {filteredDoc.type.replace("-", " ")}
                        </p>
                        <Image
                          src={filteredDoc.file}
                          zoomSrc={filteredDoc.file}
                          alt={filteredDoc.type}
                          preview
                          width="100%"
                          height="300px"
                        />
                      </div>
                    </>
                  )}
                </div>
              ))
            ) : (
              <p>No supporting documents available.</p>
            )}
          </section>
        </article>
      </div>
      <ApprovalActions
        id={id}
        records={records}
        setApproveSuccess={setApproveSuccess}
        approveSuccess={approveSuccess}
        approveApi={ApproveBirthRegistrationsApi}
        queryApi={QueryBirthRegistrationsApi}
        ApprovalStatus={+records?.Approval_Status}
        approvalStatusType={approvalStatus}
      />
    </div>
  );
};

export default SingleBirth;
