import React, { useRef, useState, useEffect } from "react";
import "./admin.css";

import naija from "../assets/images/ci.svg";

import { Tooltip } from "recharts";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAttestRegz } from "../redux/apiCalls";
import authFetch from "../axios";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { handleChange, clearFilters } from "../redux/attestSlice";
import { dateFormatter } from "../utils";

const PrivateHos = () => {
  const dispatch = useDispatch();

  const [rez, setRez] = useState({});
  const { isLoading, search, result_per_page, page, type, registerations } =
    useSelector((state) => state.attest);

  const typeOptions = [
    { label: "Approved", value: "approved" },
    { label: "Queried", value: "queried" },
    { label: "Pending", value: "pending" },
  ];
  const clearForm = () => {
    dispatch(clearFilters());
  };
  useEffect(() => {
    const getStatz = async () => {
      try {
        const statistics = await authFetch.get(
          "/state-director/birth-attestation/stats"
        );
        console.log(statistics.data);
        setRez(statistics.data);
      } catch (error) {
        console.log(error);
      }
    };

    getStatz();
  }, []);
  useEffect(() => {
    getAttestRegz(dispatch, search, result_per_page, page, type);
  }, [dispatch, search, result_per_page, page, type]); // eslint-disable-line react-hooks/exhaustive-deps

  const statusBodyTemplate2 = (rowData) => {
    return (
      <Link
        className={`btn btn-primary text-primary font-bold`}
        to={`/single/${rowData.id}`}
      >
        VIEW
      </Link>
    );
  };

  const renderHeader1 = () => {
    return (
      <div className=" hidden md:flex justify-content-between">
        <div className="grid ">
          <div className="col-12 md:col-6">
            <InputText
              value={search}
              onChange={(e) =>
                dispatch(
                  handleChange({ name: "search", value: e.target.value })
                )
              }
              placeholder="Keyword Search"
            />
          </div>

          <div className="col-12 md:col-6">
            <Dropdown
              value={type}
              options={typeOptions}
              onChange={(e) =>
                dispatch(handleChange({ name: "type", value: e.value }))
              }
              placeholder="Select type"
            />
          </div>

          <div className="col-12 md:col-4">
            <Button
              label="Reset Filter"
              className="p-button-warning"
              onClick={clearForm}
            />
          </div>
        </div>
      </div>
    );
  };
  const header1 = renderHeader1();
  const dt = useRef(null);

  return (
    <>
      <div className="grid my-3">
        <div className="col-12 " style={{ backgroundColor: "#10A6AB" }}>
          <p className="text-white m-0 text-sm font-bold">
            Total Attestation Registrations:{rez?.total}
          </p>
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <div
            className=" shadow-2 p-3 border-round text-white"
            style={{ background: "#2F96FB" }}
          >
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block  text-sm font-medium  mb-3">
                  Pendings Approvals
                </span>
                <div className=" font-bold text-xl">{rez?.Pending}</div>
              </div>
              <div
                className="flex align-items-center justify-content-center "
                style={{ width: "2.8rem", height: "2.8rem" }}
              >
                <img src={naija} className="w-full" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <div
            className=" shadow-2 p-3 border-round text-white"
            style={{ background: "#FEA93B" }}
          >
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block  text-sm font-medium  mb-3">
                  Approved Registrations
                </span>
                <div className=" font-bold text-xl"> {rez?.Approved}</div>
              </div>
              <div
                className="flex align-items-center justify-content-center "
                style={{ width: "2.8rem", height: "2.8rem" }}
              >
                <img src={naija} className="w-full" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <div
            className=" shadow-2 p-3 border-round text-white"
            style={{ background: "#2F96FB" }}
          >
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block  text-sm font-medium  mb-3">
                  Queried Records
                </span>
                <div className=" font-bold text-xl">{rez?.Queried}</div>
              </div>
              <div
                className="flex align-items-center justify-content-center "
                style={{ width: "2.8rem", height: "2.8rem" }}
              >
                <img src={naija} className="w-full" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <div
            className="shadow-2 p-3 border-round text-white"
            style={{ background: "#0D5094" }}
          >
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block  text-sm font-medium  mb-3">
                  Printed Certificates
                </span>
                <div className=" font-bold text-xl">{rez?.Printed}</div>
              </div>
              <div
                className="flex align-items-center justify-content-center "
                style={{ width: "2.8rem", height: "2.8rem" }}
              >
                <img src={naija} className="w-full" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 lg:col-12 mt-2">
          <div className="card border-round shadow-2 p-3 ">
            <div className="mb-3 flex align-items-center justify-content-between p-3">
              <span className="text-xl font-medium text-900 capitalize">
                {type} records
              </span>
            </div>
            <DataTable
              value={registerations}
              ref={dt}
              loading={isLoading}
              stripedRows
              responsiveLayout="scroll"
              header={header1}
              paginator
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              rows={5}
              rowsPerPageOptions={[5, 10, 50]}
            >
              <Column field="firstname" header="firstname"></Column>
              <Column field="surname" header="surname "></Column>
              <Column
                field="attestation_number"
                header="Certificate number"
              ></Column>
              <Column
                field="date_created"
                header="Date created"
                body={(rowData) => dateFormatter(rowData.date_created)}
              ></Column>
              <Column field="" header="Action" body={statusBodyTemplate2} />
            </DataTable>
            <Tooltip target=".export-buttons>button" position="bottom" />
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivateHos;
