export const RecType = Object.freeze({
  Approved: "approved",
  Pending: "pending",
  Queried: "queried",
  Printed: "printed",
  Unprinted: "unprinted",
});
export const PrintStatus = Object.freeze({
  Printed: 1,
  Unprinted: 0,
});
export const RegTypeOptions = Object.freeze({
  Assisted: 0,
  SelfService: 1,
});

export const KEYS = {
  birthToken: "token-birth",
  user: "user-birth",
};
export const approvalStatus = {
  PENDING: 0,
  APPROVED: 1,
  QUERIED: 2,
};

export const sexType = [
  { value: "1", name: "Male" },
  { value: "2", name: "Female" },
];

export const selfOptions = [
  { label: "Self Service", value: "yes" },
  { label: "Assisted", value: "no" },
];
export const typeOptions = [
  { label: "Approved", value: "approved" },
  { label: "Queried", value: "queried" },
  { label: "Pending", value: "pending" },
];
