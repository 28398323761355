import { useSelector } from "react-redux";

const modulePermissions = (requiredPermission, user) => {
  const permissionsArray = user?.permission?.permissions.split(",");

  return permissionsArray.some(
    (permission) => permission.trim() === requiredPermission
  );
};

const useModuleAccess = () => {
  const { userInfo: user } = useSelector((state) => state.user);

  const checkModuleAccess = (permission) => {
    if (typeof permission === "object") {
      return permission.some((perm) => modulePermissions(perm, user));
    } else if (typeof permission === "string") {
      return modulePermissions(permission, user);
    }
    return false;
  };

  return { checkModuleAccess };
};

export default useModuleAccess;
