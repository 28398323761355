import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { loginSuccess } from "../redux/userSlice";
import { useDispatch } from "react-redux";
import { KEYS } from "../helpers/constants";
const Login = () => {
  const [searchParams] = useSearchParams();
  const nav = useNavigate();
  const toks = searchParams.get("t");
  const dispatch = useDispatch();
  useEffect(() => {
    function parseJwt(toks) {
      var base64Url = toks.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      localStorage.setItem(KEYS.user, jsonPayload);
      localStorage.setItem(KEYS.birthToken, toks);
      const data = {
        access_token: toks,
        user: JSON.parse(jsonPayload),
      };
      dispatch(loginSuccess(data));
      nav("/");
    }
    parseJwt(toks);
  }, [toks, nav, dispatch]);
  return (
    <div className="min-h-screen flex justify-content-center align-items-centeer surface-section">
      <ProgressSpinner />
    </div>
  );
};

export default Login;
