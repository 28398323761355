/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import { Button } from "primereact/button";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ProgressSpinner } from "primereact/progressspinner";
import { getGender2, getModsSingleDeathRegs } from "../redux/apiCalls";
import { editModDeath } from "../redux/apiCalls";
import { Mod, getStatus, showDeceasedDetails } from "../utils";
const SingleModifiedDeath = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const { isLoading, reg, isEditing, isEdited } = useSelector(
    (state) => state.modDeath
  );
  const ed = (id) => {
    editModDeath(id, dispatch);
  };
  useEffect(() => {
    getModsSingleDeathRegs(dispatch, id);
  }, [dispatch, id, isEdited]);
  if (isLoading) {
    return (
      <div className="flex justify-content-center align-items-center">
        <ProgressSpinner style={{ width: "3rem", height: "3rem" }} />
      </div>
    );
  }
  return (
    <div>
      <div className="surface-card p-4 shadow-2 border-round">
        <Button
          label="Go back"
          icon="pi pi-arrow-left"
          className="p-button-sm"
          onClick={goBack}
        />
        <div className="col-12 p-3 uppercase border-bottom-1 border-green-300 text-md font-bold ">
          Particulars{" "}
        </div>
        <div className="flex flex-column lg:flex-row flex-wrap lg:justify-content-between border-bottom-3 border-green-500 gap-3">
          <div className="flex flex-column">
            <div className="font-medium text-md text-500 mb-3">
              certificate Number *
            </div>
            <div className="text-900 text-md mb-5 ">
              {reg?.death_certificate_number ?? ""}
            </div>
          </div>

          <div className="flex flex-column">
            <div className="font-medium text-md text-500 mb-3">Surname</div>
            <div className="text-900 text-md mb-5 ">
              {reg?.deceasedData?.surname ?? ""}
            </div>
          </div>
          <div className="flex flex-column">
            <div className="font-medium text-md text-500 mb-3">Firstname</div>
            <div className="text-900 text-md mb-5 ">
              {reg?.deceasedData?.firstname ?? ""}
            </div>
          </div>
          <div className="flex flex-column">
            <div className="font-medium text-md text-500 mb-3">Status</div>

            <div className="text-900 text-md mb-5 ">
              {getStatus(reg?.modified_status)}
            </div>
          </div>
        </div>
      </div>
      <div className="surface-card p-4 shadow-2 border-round my-2">
        <div className="grid grid-nogutter pt-2 border-top-1 border-bottom-1 border-green-300">
          <div className="col-12 p-3 uppercase border-bottom-1 border-green-300 text-md font-bold ">
            Additional Information
          </div>
          <div className="col-12 md:col-4 p-3">
            <div className="text-500 font-medium mb-2">Modification Type: </div>
            <div className="text-900">
              {reg?.Modification?.[0]?.mod_type ?? ""}
            </div>
          </div>
          <div className="col-12 md:col-4 p-3">
            <div className="text-500 font-medium mb-2">Field</div>
            <div className="text-900">
              {reg?.Modification?.[0]?.mod_field ?? ""}
            </div>
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">
              Reason for modification:
            </div>
            <div className="text-900">
              {reg?.Modification?.[0]?.mod_reason ?? ""}
            </div>
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Comments:</div>
            <div className="text-900">
              {reg?.Modification?.[0]?.comment ?? ""}
            </div>
          </div>
        </div>

        <div className="grid grid-nogutter pt-2 border-top-1 border-bottom-1 border-green-300">
          <div className="col-12 p-3 uppercase border-bottom-1 border-green-300 text-md font-bold ">
            Modified Information
          </div>

          {showDeceasedDetails(
            reg &&
              reg.Modification &&
              reg.Modification[0] &&
              reg.Modification[0].mod_field
          ) && (
            <>
              <div className="col-12 md:col-2 p-3">
                <div className="text-500 font-medium mb-2">
                  deceased previous name{" "}
                </div>
                <div className="text-900">
                  {reg && reg.Modification && reg.Modification[0]
                    ? reg.Modification[0].deceased_prev_firstname
                    : "-"}
                </div>
              </div>
              <div className="col-12 md:col-2 p-3">
                <div className="text-500 font-medium mb-2">
                  deceased previous surname
                </div>
                <div className="text-900">
                  {reg && reg.Modification && reg.Modification[0]
                    ? reg.Modification[0].deceased_prev_surname
                    : "-"}
                </div>
              </div>
              <div className="col-12 md:col-2 p-3">
                <div className="text-500 font-medium mb-2">
                  deceased previous middlename
                </div>
                <div className="text-900">
                  {reg && reg.Modification && reg.Modification[0]
                    ? reg.Modification[0].deceased_prev_middlename
                    : "-"}
                </div>
              </div>
              <div className="col-12 md:col-2 p-3">
                <div className="text-500 font-medium mb-2">
                  deceased previous sex
                </div>
                <div className="text-900">
                  {reg && reg.Modification && reg.Modification[0]
                    ? getGender2(reg.Modification[0].deceased_prev_sex)
                    : "-"}
                </div>
              </div>
              <div className="col-12 md:col-2 p-3">
                <div className="text-500 font-medium mb-2">deceased age</div>
                <div className="text-900">
                  {reg && reg.Modification && reg.Modification[0]
                    ? reg.Modification[0].deceased_age_at_death
                    : "-"}
                </div>
              </div>
              <div className="col-12 md:col-2 p-3">
                <div className="text-500 font-medium mb-2">deceased name </div>
                <div className="text-900">
                  {reg && reg.Modification && reg.Modification[0]
                    ? reg.Modification[0].deceased_firstname
                    : "-"}
                </div>
              </div>
              <div className="col-12 md:col-2 p-3">
                <div className="text-500 font-medium mb-2">
                  deceased surname
                </div>
                <div className="text-900">
                  {reg && reg.Modification && reg.Modification[0]
                    ? reg.Modification[0].deceased_surname
                    : "-"}
                </div>
              </div>
              <div className="col-12 md:col-2 p-3">
                <div className="text-500 font-medium mb-2">
                  deceased middlename
                </div>
                <div className="text-900">
                  {reg && reg.Modification && reg.Modification[0]
                    ? reg.Modification[0].deceased_middlename
                    : "-"}
                </div>
              </div>
              <div className="col-12 md:col-2 p-3">
                <div className="text-500 font-medium mb-2">deceased sex</div>
                <div className="text-900">
                  {reg && reg.Modification && reg.Modification[0]
                    ? getGender2(reg.Modification[0].deceased_sex)
                    : "-"}
                </div>
              </div>

              <div className="col-12 md:col-2 p-3">
                <div className="text-500 font-medium mb-2">
                  deceased date of death{" "}
                </div>
                <div className="text-900">
                  {reg && reg.Modification && reg.Modification[0]
                    ? reg.Modification[0].deceased_date_of_death
                    : "-"}
                </div>
              </div>
              <div className="col-12 md:col-2 p-3">
                <div className="text-500 font-medium mb-2">
                  deceased previous Age
                </div>
                <div className="text-900">
                  {reg && reg.Modification && reg.Modification[0]
                    ? reg.Modification[0].deceased_prev_age_at_death
                    : "-"}
                </div>
              </div>
              <div className="col-12 md:col-2 p-3">
                <div className="text-500 font-medium mb-2">
                  {" "}
                  deceased previous date of death"
                </div>
                <div className="text-900">
                  {reg && reg.Modification && reg.Modification[0]
                    ? reg.Modification[0].deceased_prev_date_of_death
                    : "-"}
                </div>
              </div>
            </>
          )}
        </div>

        {reg && reg.modified_status != Mod.Approved && (
          <Button
            label="Approve"
            className="p-button-success my-2"
            loading={isEditing}
            onClick={() => ed(id)}
          />
        )}
      </div>
    </div>
  );
};

export default SingleModifiedDeath;
