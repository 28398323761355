import { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import Certificate from "../components/Certificate";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ProgressSpinner } from "primereact/progressspinner";
import { getCertificateTemplates } from "../redux/apiCalls";

function BulkPrintBirthCertificate() {
  const componentRef = useRef();
  const { successRecords, errorRecords } = useSelector((state) => state.birth);
  const [backgroundData, setBackgroundData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getCertificateTemplates();
        setBackgroundData(data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.error(error.response?.message, {
          position: "top-left",
        });
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center">
          <ProgressSpinner />
        </div>
      ) : (
        <>
          {backgroundData ? (
            <>
              <div
                style={{
                  position: "sticky",
                  backgroundColor: "#f8f9fa",
                  height: "73px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {successRecords?.length > 0 && (
                  <div className="w-full mb-4">
                    <ReactToPrint
                      trigger={() => {
                        return (
                          <Button
                            type="button"
                            className="p-button-success"
                            label="Print Certificates"
                          />
                        );
                      }}
                      content={() => componentRef.current}
                    />
                  </div>
                )}
              </div>
              {errorRecords.length > 0 && (
                <div className="card border-round shadow-2 p-3 mb-3 border-1 border-red-200">
                  <h2 className="text-xl font-medium text-900 mb-3">Certificates with Errors:</h2>
                  <ul>
                    {errorRecords.map((record) => (
                      <li key={record.id} className="mb-2">
                        Certificate with ID: <span className="font-bold">{record.id},</span> has Error: {record.status}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {successRecords?.length > 0 && (
                <div ref={componentRef}>
                  {successRecords?.map((item, index) => {
                    return (
                      <div key={index}>
                        <div className=" mx-auto w-9">
                          <Certificate
                            backgroundData={backgroundData}
                            cerData={{
                              RegistrationCenter: item["Registration Center:"],
                              certificateNumber: item["Certificate Number:"],
                              fullname: item["Full Name:"],
                              sex: item["Sex:"],
                              dateOfBirth: item["Date Of Birth:"],
                              state: item["State:"],
                              lga: item["LGA:"],
                              town: item["LGA:"] + " / " + item["State:"],
                              placeOfBirth: item["Place Of Birth:"],
                              motherFullname: item["Full Name Of Mother:"],
                              fatherFullname: item["Full Name Of Father:"],
                              qrcodeData: item["qrcodeData"],
                              acknowledgement: item["acknoladgement"],
                              acknowledgement2: item["acknoladgement2"],
                              acknowledgement3: item["acknoladgement3"],
                              date: item["Date:"],
                              director: item["director"],
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          ) : (
            "no record found"
          )}
        </>
      )}
    </>
  );
}

export default BulkPrintBirthCertificate;

