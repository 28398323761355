import axios from "axios";
import store from "./redux/store";
import { logout } from "./redux/userSlice";
import { KEYS } from "./helpers/constants";

const authFetch = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

authFetch.interceptors.request.use(
  function (req) {
    const token = localStorage.getItem(KEYS.birthToken);
    
    if (token) {
      
      req.headers.authorization = `Bearer ${token}`;
      return req;
    }
    return req;
  },
  function (error) {
    return Promise.reject(error);
  }
);

authFetch.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response?.status === 401) {
        
        store.dispatch(logout());
      }
    }
    return Promise.reject(error);
  }
);

export default authFetch;
