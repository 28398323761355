import QRCode from "qrcode";

export const generateQRCode = async (data) => {
  const barcode = await QRCode.toDataURL(data, {
    color: {
      light: "#0000", 
    },
    scale: 2,
  });
  return barcode;
};

export const certificatBg = () => {
  return process.env.REACT_APP_IMAGE_DB;
};

export const directorName = () => {
  return process.env.REACT_APP_NPC_DIRECTOR_NAME;
};

export const directorSignature = () => {
  return process.env.REACT_APP_DIRECTOR_SIGNATURE;
};
