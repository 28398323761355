import React, { useEffect, useState, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";

import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";

import { Tooltip } from "primereact/tooltip";
const RegList = () => {
  const [loading1, setLoading1] = useState(true);
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const getDatz = async () => {
      const statz = await axios.get(
        "https://jsonplaceholder.typicode.com/users"
      );

      setProducts(statz.data);

      setLoading1(false);
    };
    getDatz();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    initFilters1();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    setGlobalFilterValue1("");
  };

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    console.log(value);
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;
    console.log(_filters1);
    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };
  const statusBodyTemplate2 = (rowData) => {
    return (
      <Link
        className={`btn btn-primary text-primary font-bold`}
        to={`/single-registrars/${rowData.id}`}
      >
        VIEW
      </Link>
    );
  };
  const clearFilter1 = () => {
    initFilters1();
  };
  const renderHeader1 = () => {
    return (
      <div className=" hidden md:flex justify-content-between">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear"
          className="p-button-outlined"
          onClick={clearFilter1}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };
  const header1 = renderHeader1();
  const dt = useRef(null);

  return (
    <>
      <div className="grid mt-2">
        <div className="col-12 lg:col-12">
          <div className="card border-round shadow-2 p-3 ">
            <div className="mb-3 flex align-items-center justify-content-between p-3">
              <span className="text-xl font-medium text-900">
                Registrars List(Adamawa)
              </span>
              <div className="flex align-items-center export-buttons">
                <Link to={"/registrars-new"}>
                  <Button
                    type="button"
                    icon="pi pi-user"
                    label="Create new"
                    className="p-button-success mr-2"
                  />
                </Link>
              </div>
            </div>
            <DataTable
              value={products}
              ref={dt}
              filters={filters1}
              loading={loading1}
              stripedRows
              responsiveLayout="stack"
              header={header1}
              paginator
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              rows={5}
              rowsPerPageOptions={[5, 10, 50]}
            >
              <Column field="username" header="Name"></Column>
              <Column field="email" header="Email"></Column>
              <Column field="phone" header="Phone number"></Column>
              <Column field="name" header="LGA"></Column>
              <Column field="username" header="Registration Centre"></Column>
              <Column field="email" header="Total Registrations"></Column>
              <Column field="email" header="Volunteers"></Column>

              <Column field="" header="Action" body={statusBodyTemplate2} />
            </DataTable>
            <Tooltip target=".export-buttons>button" position="bottom" />
          </div>
        </div>
      </div>
    </>
  );
};

export default RegList;
