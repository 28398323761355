import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import ErrorMessageExtractor from "../../helpers/utils/errorHandler";
import { RegTypeOptions } from "../../helpers/constants";
import { addPrintRecord, previewCertificate } from "../../redux/apiCalls";
import ReactToPrint from "react-to-print";
import { ProgressSpinner } from "primereact/progressspinner";

const ApprovalActions = ({
  id,
  records,
  setApproveSuccess,
  approveSuccess,
  approveApi,
  queryApi,
  ApprovalStatus,
  approvalStatusType,
}) => {
  const toast = useRef(null);
  const [isPrinting, setIsPrinting] = useState(false);

  const promiseResolveRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [queryVisible, setQueryVisible] = useState(false);
  const [isQueryReasons, setIsQueryReasons] = useState(false);
  const [queryReasons, setQueryReasons] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  const [showPrintModal, setShowPrintModal] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const componentRef = useRef();

  const handleApproveRecord = async () => {
    setSubmitLoading(true);
    try {
      const response = await approveApi(id);
      setSubmitLoading(false);
      if (response.success === true) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: `${response.message ?? "Registration approved"}`,
        });
        setVisible(false);
        setTimeout(() => {
          setApproveSuccess(!approveSuccess);
        }, 1500);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `${response.message ?? "Registration not approved"}`,
        });
      }
    } catch (error) {
      setSubmitLoading(false);
      const message = ErrorMessageExtractor.errorParser(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `${message ?? "Record Not Approved"}`,
      });
      setVisible(false);
    }
  };
  const handleQueryRecord = async () => {
    setSubmitLoading(true);
    try {
      const response = await queryApi({
        id: Number(id),
        reason: queryReasons,
      });
      setSubmitLoading(false);
      if (response.success === true) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: `${response.message ?? "Registration approved"}`,
        });
        setQueryVisible(false);
        setTimeout(() => {
          setApproveSuccess(!approveSuccess);
        }, 1500);
      } else {
        setSubmitLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `${response.message ?? "Registration not approved"}`,
        });
      }
    } catch (error) {
      setSubmitLoading(false);
      const message = ErrorMessageExtractor.errorParser(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `${message ?? "Record Not Queried"}`,
      });
    }
  };
  const handleOpenPrintModal = async () => {
    setShowPrintModal(true);
    setPrintLoading(true);
    try {
      const response = await previewCertificate(records?.Certificate_No);
      setPrintLoading(false);
      setPreviewData(response);
    } catch (error) {
      setPrintLoading(false);
      setShowPrintModal(false);
      const message = ErrorMessageExtractor.errorParser(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: message || "Failed to load certificate preview",
      });
    }
  };
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  const handlePrintRecord = async () => {
    setPrintLoading(true);
    try {
      const response = await addPrintRecord(id);
      if (response.success) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Print record added successfully. Proceeding to print...",
        });
        return new Promise((resolve) => {
          promiseResolveRef.current = resolve;
          setIsPrinting(true);
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.message ?? "Failed to add print record",
        });
      }
    } catch (error) {
      const message = ErrorMessageExtractor.errorParser(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `${message ?? "Failed to add print record"}`,
      });
      return new Promise(() => {
        promiseResolveRef.current = null;
        setIsPrinting(true);
        setShowPrintModal(false)
      });
    } finally {
      setPrintLoading(false);
    }
  };


  return (
    <>
      <div className="flex gap-3">
        {[approvalStatusType.PENDING, approvalStatusType.QUERIED].includes(
          ApprovalStatus
        ) && (
            <Button
              label="Approve"
              className="p-button-success my-2"
              onClick={() => {
                setVisible(true);
              }}
            />
          )}
        {+records?.self !== RegTypeOptions.SelfService && (
          <>
            {ApprovalStatus !== approvalStatusType.QUERIED && (
              <div className="flex gap-3">
                <Button
                  label="Query"
                  className="p-button-warning my-2"
                  onClick={() => setQueryVisible(true)}
                />
                {ApprovalStatus === approvalStatusType.APPROVED && (
                  <Button
                    type="button"
                    label="Print"
                    className="p-button-success my-2"
                    onClick={handleOpenPrintModal}
                    loading={printLoading}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>

      <Dialog
        header="Certificate Preview"
        visible={showPrintModal}
        style={{ width: "40vw" }}
        onHide={() => setShowPrintModal(false)}
      >
        <div className="flex flex-col align-items-center">
          {printLoading ? (
            <ProgressSpinner style={{ width: '50px', height: '50px' }} />
          ) : previewData ? (
            <div style={{ position: 'relative', width: '100%', height: 'auto' }}>
              <img
                ref={componentRef}
                src={`data:image/png;base64,${previewData}`}
                alt="Certificate Preview"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)"
                }}
                onContextMenu={(e) => e.preventDefault()}
              />
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0)'
                }}
                onContextMenu={(e) => e.preventDefault()}
              />
            </div>
          ) : (
            <p className="text-center" style={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              No preview available.
            </p>
          )}
        </div>

        <div className="flex justify-content-between mt-3">
          <Button
            label="Close"
            onClick={() => setShowPrintModal(false)}
            className="p-button-secondary"
          />
          <div className="ml-2">
            <ReactToPrint
              trigger={() => (
                <Button
                  type="button"
                  className="p-button-success"
                  label="Proceed to Print"
                />
              )}
              onBeforePrint={handlePrintRecord}
              onAfterPrint={() => {
                promiseResolveRef.current = null;
                setIsPrinting(false);
                setShowPrintModal(false)
              }}
              content={() => componentRef.current}
            />
          </div>
        </div>
      </Dialog>

      <Dialog
        header="NOTE"
        visible={visible}
        style={{ width: "30vw" }}
        onHide={() => setVisible(false)}
      >
        <p>Are you sure you want to approve this registration?</p>
        <div className="w-full flex gap-3 justify-content-between">
          <Button
            label="YES"
            className="w-full p-button-success my-2"
            loading={submitLoading}
            onClick={handleApproveRecord}
          />
          <Button
            label="NO"
            className=" w-full bg-transparent text-primary my-2"
            onClick={() => setVisible(false)}
          />
        </div>
      </Dialog>
      <Dialog
        header="NOTE"
        visible={queryVisible}
        style={{ width: "30vw" }}
        onHide={() => setQueryVisible(false)}
      >
        {isQueryReasons ? (
          <>
            <InputTextarea
              value={queryReasons}
              placeholder="Enter Reason for Querying this record"
              onChange={(e) => setQueryReasons(e.target.value)}
              rows={5}
              cols={40}
            />{" "}
            <div className="w-full flex gap-3 justify-content-between">
              <Button
                label="SUBMIT"
                onClick={handleQueryRecord}
                loading={submitLoading}
                className="w-full p-button-warning my-2"
              />
              <Button
                label="CANCEL"
                onClick={() => {
                  setQueryVisible(false);
                }}
                className=" w-full bg-transparent text-primary my-2"
              />
            </div>
          </>
        ) : (
          <>
            <p>Are you sure you want to query this registration?</p>
            <div className="w-full flex gap-3 justify-content-between">
              <Button
                label="YES"
                className="w-full p-button-warning my-2"
                onClick={() => {
                  setIsQueryReasons(true);
                }}
              />
              <Button
                label="NO"
                onClick={() => {
                  setQueryVisible(false);
                }}
                className=" w-full bg-transparent text-primary my-2"
              />
            </div>
          </>
        )}
      </Dialog>
      <Toast ref={toast} position="top-center" />
    </>
  );
};

export default ApprovalActions;
