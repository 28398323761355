const RecordDetails = ({
  type,
  header,
  records,
  fieldsToDisplay,
  fieldProcessors,
}) => {
  const containsFace = fieldsToDisplay.includes("face");
  return (
    <>
      {records && (

        <div className="flex flex-column gap-5">
          <article className="shadow-lg bg-white text-sm px-4 py-2 box-shadow-css">
            {type && (
              <h2 className="text-sm text-black-alpha-90 font-bold">
                <span className="border-bottom-2 border-blue-600">
                  Particulars{" "}
                </span>{" "}
                of <span className="capitalize">{type}</span>
              </h2>
            )}
            {header && (
              <h2 className="text-sm text-black-alpha-90 font-bold">
                <span className="border-bottom-2 border-blue-600 capitalize">
                  {header}
                </span>
              </h2>
            )}
            <div className="md:flex ">
              <section className="w-full" style={{ wordBreak: "break-word" }}>
                <div className="grid py-3">
                  {fieldsToDisplay
                    ?.filter((fieldName) => fieldName !== "face")
                    ?.map((fieldName) => (
                      <div
                        className={`flex col-12 ${containsFace ? " lg:col-4" : "lg:col-3"
                          } flex-column gap-2 py-3 text-xs`}
                        key={fieldName}
                      >
                        <span className="capitalize">
                          {fieldName?.replace(/_/g, " ")}
                        </span>
                        <span className="font-semibold uppercase">
                          {/* Check if the field needs processing */}
                          {fieldProcessors[fieldName]
                            ? fieldProcessors[fieldName](records)
                            : records[fieldName] ?? "----"}
                        </span>
                      </div>
                    ))}
                </div>
              </section>
              {fieldsToDisplay
                ?.filter((fieldName) => fieldName === "face")
                ?.map((fieldName) => (
                  <div
                    className="flex flex-column gap-2 py-3 text-xs"
                    key={fieldName}
                  >
                    <span className="capitalize">
                      {fieldName?.replace(/_/g, " ")}
                    </span>
                    <span className="font-semibold uppercase">
                      {/* Check if the field needs processing */}
                      {fieldProcessors[fieldName]
                        ? fieldProcessors[fieldName](records)
                        : records[fieldName] ?? "----"}
                    </span>
                  </div>
                ))}
            </div>
          </article>
        </div>
      )}
    </>
  );
};

export default RecordDetails;
