import React, { useRef } from "react";
import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";
import { Outlet, Link, useNavigate } from "react-router-dom";
import Logo from "../assets/images/72.png";
import "./home.css";
import { AdminAccessList, RegistrarAccessList, USER_ROLE } from "../utils";
import useModuleAccess from "../helpers/hooks/useModuleAccess";
import packageJson from "../../package.json";
import { KEYS } from "../helpers/constants";
const Homez = () => {
  const nav = useNavigate();
  const { checkModuleAccess } = useModuleAccess();

  const handleLogout = () => {
    localStorage.removeItem(KEYS.user);
    localStorage.removeItem(KEYS.birthToken);
    nav("/unauth");
  };
  const btnRef3 = useRef(null);
  const btnRef4 = useRef(null);
  const btnRef5 = useRef(null);
  const btnRef8 = useRef(null);
  const btnRef9 = useRef(null);
  const userInfo = JSON.parse(localStorage.getItem(KEYS.user));
  const hasAdminAccess = checkModuleAccess(AdminAccessList);
  const hasRegistrarAccess = checkModuleAccess(RegistrarAccessList);
  const token = localStorage.getItem(KEYS.birthToken);

  return (
    <div className="min-h-screen flex relative lg:static surface-ground">
      <div
        id="app-sidebar-1"
        className="surface-section h-screen hidden lg:block flex-shrink-0 absolute lg:sticky left-0 top-0 z-2 select-none"
        style={{ width: "280px" }}
      >
        <div
          className="flex flex-column h-full"
          style={{ backgroundColor: "#0E7706" }}
        >
          <div
            className="flex align-items-center px-5 flex-shrink-0"
            style={{ height: "65px", backgroundColor: "white" }}
          >
            <Link
              to="/"
              className="flex align-items-center cursor-pointer gap-2"
            >
              <img src={Logo} alt="hyper-300" height="45" />
              <span className="text-sm text-green-500 font-bold">
                VITAL REG Management System
              </span>
            </Link>
          </div>
          <div className="overflow-y-auto mt-3">
            <ul className="list-none p-3 m-0 ">
              {userInfo?.permission?.permissions.includes(
                "Can_List_Attestation"
              ) && (
                <li className="border-bottom-1 border-white">
                  <Link
                    to="/"
                    className="flex align-items-center cursor-pointer p-3 transition-duration-150 transition-colors w-full"
                  >
                    <i className="pi pi-file mr-2 text-white font-bold"></i>
                    <span className="font-medium text-white font-medium">
                      Birth Attestations
                    </span>
                    <Ripple />
                  </Link>
                </li>
              )}

              {userInfo?.permission?.permissions.includes(
                "Can_List_Modification"
              ) && (
                <li className="border-bottom-1 border-white">
                  <StyleClass
                    nodeRef={btnRef8}
                    selector="@next"
                    enterClassName="hidden"
                    enterActiveClassName="slidedown"
                    leaveToClassName="hidden"
                    leaveActiveClassName="slideup"
                  >
                    {/* eslint-disable-next-line */}
                    <a
                      ref={btnRef8}
                      className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-user-edit mr-2 text-white font-bold"></i>
                      <span className="text-white font-medium">
                        Modifications
                      </span>
                      <i className="pi pi-chevron-right ml-auto mr-1 text-white"></i>
                      <Ripple />
                    </a>
                  </StyleClass>
                  <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                    <li>
                      <Link
                        to="/modify-birth"
                        className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                      >
                        <i className="pi pi-chart-line mr-2 text-white font-bold"></i>
                        <span className="font-medium text-white">
                          Birth Registrations
                        </span>
                        <Ripple />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/modify-attest"
                        className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                      >
                        <i className="pi pi-chart-line mr-2 text-white font-bold"></i>
                        <span className="font-medium text-white">
                          Birth Attestations
                        </span>
                        <Ripple />
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/modify-death"
                        className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                      >
                        <i className="pi pi-chart-line mr-2 text-white font-bold"></i>
                        <span className="font-medium text-white">
                          Death Registrations
                        </span>
                        <Ripple />
                      </Link>
                    </li>
                  </ul>
                </li>
              )}

              {userInfo?.permission?.permissions.includes("Can_List_Birth") && (
                <li className="border-bottom-1 border-white">
                  <Link
                    to="/birth"
                    className="flex align-items-center cursor-pointer p-3 transition-duration-150 transition-colors w-full"
                  >
                    <i className="pi pi-file mr-2 text-white font-bold"></i>
                    <span className="font-medium text-white font-medium">
                      Birth registrations
                    </span>
                    <Ripple />
                  </Link>
                </li>
              )}
              {userInfo?.permission?.permissions.includes("Can_List_Death") && (
                <li className="border-bottom-1 border-white">
                  <Link
                    to="/death"
                    className="flex align-items-center cursor-pointer p-3 transition-duration-150 transition-colors w-full"
                  >
                    <i className="pi pi-file mr-2 text-white font-bold"></i>
                    <span className="font-medium text-white font-medium">
                      Death registrations
                    </span>
                    <Ripple />
                  </Link>
                </li>
              )}

              {userInfo?.permission?.permissions.includes(
                "Can_List_Stillbirth"
              ) && (
                <li className="border-bottom-1 border-white">
                  <Link
                    to="/still"
                    className="flex align-items-center cursor-pointer p-3 transition-duration-150 transition-colors w-full"
                  >
                    <i className="pi pi-file mr-2 text-white font-bold"></i>
                    <span className="font-medium text-white font-medium">
                      Still birth registrations
                    </span>
                    <Ripple />
                  </Link>
                </li>
              )}

              {userInfo?.roles?.id === USER_ROLE.DCR && (
                <li className="border-bottom-1 border-white">
                  <Link
                    to="/registrars"
                    className="flex align-items-center cursor-pointer p-3 transition-duration-150 transition-colors w-full"
                  >
                    <i className="pi pi-file mr-2 text-white font-bold"></i>
                    <span className="font-medium text-white font-medium">
                      Registrars
                    </span>
                    <Ripple />
                  </Link>
                </li>
              )}

              <li className="border-bottom-1 border-200">
                <StyleClass
                  nodeRef={btnRef9}
                  selector="@next"
                  enterClassName="hidden"
                  enterActiveClassName="slidedown"
                  leaveToClassName="hidden"
                  leaveActiveClassName="slideup"
                >
                  {/* eslint-disable-next-line */}
                  <a
                    ref={btnRef9}
                    className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                transition-duration-150 transition-colors w-full"
                  >
                    <i className="pi pi-plus mr-2 text-white font-bold"></i>
                    <span className="font-medium text-white font-medium">
                      Other Access
                    </span>
                    <i className="pi pi-chevron-right ml-auto mr-1 text-white"></i>
                    <Ripple />
                  </a>
                </StyleClass>
                <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                  {hasAdminAccess && (
                    <li>
                      <Link
                        to={`${process.env.REACT_APP_ADMIN_LINK}/login?t=${token}`}
                        target="_blank"
                        className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                      >
                        <i className="pi pi-chart-line mr-2 text-white font-bold"></i>
                        <span className="font-medium text-sm text-white">
                          Admin{" "}
                        </span>
                        <Ripple />
                      </Link>
                    </li>
                  )}

                  {hasRegistrarAccess && (
                    <li>
                      <Link
                        to={`${process.env.REACT_APP_PROD_LINK}/login?t=${token}`}
                        target="_blank"
                        className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                      >
                        <i className="pi pi-chart-line mr-2 text-white font-bold"></i>
                        <span className="font-medium text-sm text-white">
                          Registrar{" "}
                        </span>
                        <Ripple />
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </div>
          <div className="mt-auto mx-3">
            <ul className="list-none p-2 m-0 hidden">
              <li onClick={handleLogout}>
                {/* eslint-disable-next-line */}
                <a
                  className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
            transition-duration-150 transition-colors w-full"
                >
                  <i className="pi pi-sign-out mr-2"></i>
                  <span className="font-medium text-white">Sign Out</span>
                  <Ripple />
                </a>
              </li>
            </ul>
            <StyleClass
              nodeRef={btnRef3}
              selector="@prev"
              enterClassName="hidden"
              enterActiveClassName="fadein"
              leaveToClassName="hidden"
              leaveActiveClassName="fadeout"
            >
              {/* eslint-disable-next-line */}
              <a
                ref={btnRef3}
                className="p-ripple my-3 px-3 py-2 flex align-items-center hover:bg-bluegray-900 border-round cursor-pointer text-bluegray-100 hover:text-bluegray-50
        transition-duration-150 transition-colors w-full"
              >
                <span className="font-medium text-white">
                  {userInfo?.UserName}
                </span>
                <i className="pi pi-chevron-up ml-auto text-white"></i>
                <Ripple />
              </a>
            </StyleClass>

            <p className="text-center text-xs text-white">
              v {packageJson.version}
            </p>
          </div>
        </div>
      </div>
      <div className="min-h-screen flex flex-column relative flex-auto">
        <div
          className="flex justify-content-between align-items-center px-5  shadow-2 relative lg:sticky top-0 left-0 border-bottom-1 surface-border z-1"
          style={{ height: "63px", backgroundColor: "#0E7706" }}
        >
          <div className="flex">
            <StyleClass
              nodeRef={btnRef4}
              selector="#app-sidebar-1"
              enterClassName="hidden"
              enterActiveClassName="fadeinleft"
              leaveToClassName="hidden"
              leaveActiveClassName="fadeoutleft"
              hideOnOutsideClick
            >
              {/* eslint-disable-next-line */}
              <a
                ref={btnRef4}
                className="p-ripple cursor-pointer block lg:hidden text-white mr-3"
              >
                <i className="pi pi-bars text-4xl"></i>
                <Ripple />
              </a>
            </StyleClass>
          </div>
          <StyleClass
            nodeRef={btnRef5}
            selector="@next"
            enterClassName="hidden"
            enterActiveClassName="fadein"
            leaveToClassName="hidden"
            leaveActiveClassName="fadeout"
            hideOnOutsideClick
          >
            {/* eslint-disable-next-line */}
            <a
              ref={btnRef5}
              className="p-ripple cursor-pointer block lg:hidden text-700"
            >
              <i className="pi pi-ellipsis-v text-2xl"></i>
              <Ripple />
            </a>
          </StyleClass>
        </div>
        <div className="p-5 flex flex-column flex-auto">
          <div className=" flex-auto">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homez;
