import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../components/Loading";
import { USER_ROLE } from "../utils";
const ProtectedRouteAdmin = ({ children }) => {
  const ALLOWED_ROLES = [
    USER_ROLE.STATE_DIRECTOR,
    USER_ROLE.DCR,
    USER_ROLE.ADMIN,
    USER_ROLE.REGISTRAR,
    USER_ROLE.SUBADMIN,
  ];
  const { userInfo } = useSelector((state) => state.user);

  if (!userInfo) {
    return (
      <div className="flex justify-content-center align-items-center h-screen">
        <Loading />
      </div>
    );
  }
    return ALLOWED_ROLES.includes(userInfo.Role_ID) ? (
      children
    ) : (
      <Navigate to="/unauth" />
    );

};

export default ProtectedRouteAdmin;
