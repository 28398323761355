import { createSlice } from "@reduxjs/toolkit";
import { RecType } from "../helpers/constants";

export const birthSlice = createSlice({
  name: "birth",
  initialState: {
    registerations: [],
    certificates: [],
    reg: {},
    isLoading: false,
    search: "",
    result_per_page: 20,
    page: 1,
    numPages: 1,
    type: "",
    self:"",
    printStatus: RecType.Unprinted,
    isEditing: false,
    isEdited: false,
    errorRecords: [],
    successRecords: [],
  },

  reducers: {
    getRegStart: (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    getRegSuccess: (state, action) => {
      return {
        ...state,
        isLoading: false,
        registerations: action.payload.result,
        numPages: action.payload.pagination.total,
      };
    },
    uploadCertificates: (state, action) => {
      return {
        ...state,
        certificates: action.payload,
      };
    },
    getRegFailure: (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },

    getSingleRegStart: (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    getSingleRegSuccess: (state, action) => {
      return {
        ...state,
        isLoading: false,
        reg: action.payload,
        
      };
    },
    getSingleRegFailure: (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
    changePage: (state, action) => {
      return {
        ...state,
        page: action.payload,
      };
    },
    handleChange: (state, action) => {
      return {
        ...state,
        page: 1,
        [action.payload.name]: action.payload.value,
      };
    },
    editBirthRegStart: (state) => {
      return {
        ...state,
        isEditing: true,
      };
    },
    editBirthRegSuccess: (state, action) => {
      return {
        ...state,
        isEditing: false,
        isEdited: true,
        
      };
    },
    editBirthRegFailure: (state) => {
      return {
        ...state,
        isEditing: false,
        isEdited: false,
      };
    },
    clearFilters: (state) => {
      const init = {
        search: "",
        result_per_page: 20,
        page: 1,
        type: "",
        self:"",
        printStatus: RecType.Unprinted,
      };
      return {
        ...state,
        ...init,
      };
    },
    setErrorRecords: (state, action) => {
      return {
        ...state,
        errorRecords: action.payload,
      };
    },
    setSuccessRecords: (state, action) => {
      return {
        ...state,
        successRecords: action.payload,
      };
    },
  },
});

export const {
  getRegFailure,
  getRegStart,
  getRegSuccess,
  uploadCertificates,
  changePage,
  handleChange,
  clearFilters,
  getSingleRegFailure,
  getSingleRegStart,
  getSingleRegSuccess,
  editBirthRegFailure,
  editBirthRegStart,
  editBirthRegSuccess,
  setErrorRecords,
  setSuccessRecords,
} = birthSlice.actions;

export default birthSlice.reducer;
